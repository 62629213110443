import styled from 'styled-components'

export const EventsListWrapper = styled.section`
  padding: 5rem 0 10rem 0;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    background: white;
    width: 100%;
    height: 100px;
    bottom: 1px;
    transform: translateY(100%);

    @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
      -webkit-clip-path: polygon(0 0, 100% 100%, 100% 100%, 100% 0);
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      backface-visibility: hidden;
      clip-path: polygon(-15% 0, 100% 50%, 100% 100%, 100% 0);
    }
  }

  @media screen and (max-width: 425px) {
    padding: 1rem 0;
  }
`
export const EventsDivider = styled.hr`
  border: none;
  background: repeating-linear-gradient(
    to right,
    ${({ theme }) => theme.colors.lightGrey} 0,
    ${({ theme }) => theme.colors.lightGrey} 10px,
    transparent 10px,
    transparent 25px
  );
  margin: 11rem 0;
  height: 2px;

  @media only screen and (max-width: 1100px) {
    margin: 7rem 0;
  }
`

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 4.28rem;
  font-weight: 800;
  line-height: 5.14rem;
  margin-bottom: 10rem;

  @media only screen and (max-width: 1100px) {
    font-size: 2.85rem;
    line-height: 3.57rem;
    text-align: center;
    margin-bottom: 4rem;
  }
`
