import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import SEO from 'components/SEO'
import Layout from 'components/Layout'
import Header from 'components/Header'
import Footer from 'components/Footer'
import EventsIntro from 'components/Events-screen/Events-intro'
import EventsList from 'components/Events-screen/Events-list'
import JobPositions from 'components/Job-positions'

const MainContent = styled.main`
  background-color: #ffffff;
`

const Events = ({ data, pageContext, location }) => {
  const {
    events_list: { edges: events },
    job_positions: { edges: position },
    events: {
      featured_media,
      title,
      excerpt,
      yoast_meta: { yoast_wpseo_title, yoast_wpseo_metadesc },
      acf: { events_intro }
    }
  } = data
  const featuredImage = featured_media !== null ? featured_media?.localFile?.childImageSharp : null

  const seo = {
    title: yoast_wpseo_title || title,
    description: yoast_wpseo_metadesc || excerpt,
    lang: pageContext.locale,
    image: featuredImage ? featuredImage.resize : null,

    pathname: location.pathname
  }

  return (
    <Layout locale={pageContext.locale}>
      <SEO
        title={seo.title}
        description={seo.description}
        pathname={seo.pathname}
        lang={seo.lang}
        location={location}
        image={seo.image}

      />
      <Header mode="dark" locale={pageContext.locale} opacity={0.3} location={location} />
      <EventsIntro data={events_intro} locale={pageContext.locale} location={location} />
      <MainContent>
        <EventsList events={events} title={events_intro.past_events_title} locale={pageContext.locale} />
        <JobPositions data={position} lang={pageContext.locale} location={location} />
      </MainContent>
      <Footer locale={pageContext.locale} location={location} />
    </Layout>
  )
}

export default Events

export const query = graphql`
  query($id: Int, $locale: String!) {
    events: wordpressPage(wordpress_id: { eq: $id }, lang: { eq: $locale }) {
      title
      excerpt
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      featured_media {
        localFile {
          childImageSharp {
            fluid(quality: 85, maxWidth: 1300) {
              ...GatsbyImageSharpFluid_withWebp
            }
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
      }
      acf {
        events_intro {
          title
          lets_talk
          past_events_title
        }
      }
    }
    events_list: allWordpressWpEvents(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          id
          featured_media {
            localFile {
              childImageSharp {
                fixed(quality: 100, width: 575, height: 400) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
          acf {
            date
            title
            location
            details
            link_text
            url
          }
        }
      }
    }
    job_positions: allWordpressWpJobs(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          acf {
            title
            text
            link_text
            url
            open_positions {
              job_position
              work_location
              slug
            }
          }
        }
      }
    }
  }
`
