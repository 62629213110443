import React from 'react'
import { Link } from 'gatsby'

import { EventsIntroWrapper, EventsIntroHero, Title, LetsTalk } from './styles'
import Container from '../../UI/Container'

const EventsIntro = ({ data, locale, location }) => {
  const { title, lets_talk } = data
  return (
    <EventsIntroWrapper>
      <Container>
        <EventsIntroHero>
          <Title>{title}</Title>
          <LetsTalk>
            <Link to={locale === 'hr' ? `/kontakt${location.search}` : `/${locale}/contact${location.search}`}>
              <span>{lets_talk}</span>
            </Link>
          </LetsTalk>
        </EventsIntroHero>
      </Container>
    </EventsIntroWrapper>
  )
}

EventsIntro.defaultProps = {
  location: {
    search: ''
  }
}

export default EventsIntro
