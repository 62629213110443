import styled from 'styled-components'

import ArrowRight from '../../../images/next-arrow.svg'

export const EventsIntroWrapper = styled.div`
  padding: 8rem 0 0;
  background: linear-gradient(261.34deg, #f7f7f7 3.39%, rgba(247, 247, 247, 0) 95.53%);
  position: relative;

  &::before {
    content: '';
    position: absolute;
    background: white;
    width: 100%;
    height: 100%;
    bottom: 0;
    z-index: 1;

    @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
      -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 34%, 0 100%);
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      backface-visibility: hidden;
      clip-path: polygon(0 100%, 100% 100%, 100% 34%, 0 100%);
    }
  }

  @media only screen and (max-width: 1100px) {
    &::after {
      content: '';
      position: absolute;
      top: 40%;
      right: 0;
      max-width: 480px;
      width: 100%;
      height: 100%;
      background-position: center;
      background: radial-gradient(50% 50% at 50% 50%, rgba(169, 207, 58, 0.26) 0%, rgba(255, 255, 255, 0) 100%),
        radial-gradient(46.32% 46.32% at 49.17% 106.62%, rgba(29, 158, 217, 0.47) 0%, rgba(29, 158, 217, 0) 100%);
      opacity: 0.7;
      transform: translateY(-18%);
      background-repeat: no-repeat;
      background-size: 480px 272px;
      pointer-events: none;
    }
  }
`

export const EventsIntroHero = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 400px;
  position: relative;
  z-index: 1;

  @media only screen and (max-width: 1100px) {
    flex-direction: column;
    justify-content: center;
  }

  @media only screen and (min-width: 1101px) {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      max-width: 480px;
      width: 100%;
      height: 100%;
      background-position: center;
      background: radial-gradient(50% 50% at 50% 50%, rgba(169, 207, 58, 0.26) 0%, rgba(255, 255, 255, 0) 100%),
        radial-gradient(46.32% 46.32% at 49.17% 106.62%, rgba(29, 158, 217, 0.47) 0%, rgba(29, 158, 217, 0) 100%);
      opacity: 0.7;
      transform: translateY(-18%);
      background-repeat: no-repeat;
      background-size: 480px 272px;
      pointer-events: none;
    }
  }
`

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 4.28rem;
  font-weight: 800;
  line-height: 5.14rem;
  flex-basis: 50%;

  @media only screen and (max-width: 768px) {
    font-size: 2.14rem;
    line-height: 2.85rem;
    text-align: center;
    flex-basis: 0;
    margin-bottom: 3rem;
  }

  @media only screen and (min-width: 769px) and (max-width: 1100px) {
    font-size: 3.14rem;
    line-height: 3.85rem;
    text-align: center;
  }
`

export const LetsTalk = styled.div`
  position: relative;
  max-width: 480px;
  background: radial-gradient(90.28% 119.86% at 92.64% 106.63%, #ed1e79 0%, #20629c 100%);
  box-shadow: 0px 4px 10px rgba(0, 133, 255, 0.2);
  border-radius: 10px;
  padding: 3.24rem 0 3.24rem 2.85rem;
  cursor: pointer;
  z-index: 2;
  transition: all 0.2s ease-in-out;

  span {
    display: flex;
    width: 70%;
    color: ${({ theme }) => theme.colors.lightestGrey};
    font-size: 1.14rem;
    font-weight: 500;
    line-height: 1.71rem;

    &::after {
      content: '';
      align-self: center;
      position: absolute;
      width: 30px;
      height: 40px;
      right: 10%;
      background-image: url(${ArrowRight});
      background-position: center;
      background-size: 12px 24px;
      background-repeat: no-repeat;
      transition: all 0.2s ease-in-out;
      transform: translateX(0px) rotate(180deg);
      transform-origin: center;
    }
  }

  &:hover {
    box-shadow: 0px 4px 20px rgba(0, 133, 255, 0.4);
    span::after {
      transform: translateX(10px) rotate(180deg);
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 2rem;
    text-align: center;

    span {
      width: 100%;

      &::after {
        display: none;
      }
    }
  }
`
