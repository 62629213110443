import styled from 'styled-components'

export const EventItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8.57rem;
  animation: fade-in 0.2s ease-in;

  @media only screen and (max-width: 1100px) {
    flex-direction: column;
    align-items: center;

    margin-bottom: 5.6rem;
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`
export const ImageContainer = styled.figure`
  max-width: 580px;
  max-height: 400px;

  .event-image {
    width: 100%;
    height: auto;
    box-shadow: 0px 4px 10px rgba(0, 133, 255, 0.2);
    border-radius: 10px;
  }

  @media only screen and (max-width: 1100px) {
    width: 100%;
    .event-image {
      width: 100% !important;
      max-height: 200px;
    }
  }

  /* @media screen and (max-width: 425px) {
    border-radius: 10px;
    overflow: hidden;
  } */
`
export const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 40%;
  padding: 2rem;

  @media only screen and (max-width: 1100px) {
    width: 100%;

    padding: 1rem 0.5rem;
  }
`
export const Date = styled.span`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-size: 1.14rem;
  font-weight: 500;
  line-height: 1.71rem;
  margin-bottom: 1.42rem;

  @media only screen and (max-width: 1100px) {
    margin: 1.42rem 0;
  }
`

export const Title = styled.h5`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 2.14rem;
  font-weight: 800;
  line-height: 2.85rem;
`

export const Location = styled.h6`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 1.42rem;
  font-weight: 500;
  line-height: 2.14rem;
  margin-bottom: 2.42rem;

  @media only screen and (max-width: 1100px) {
    width: 100%;
    margin-top: 1rem;
  }
`

export const Details = styled.div`
  width: 470px;
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 1.14rem;
  line-height: inherit;

  @media only screen and (max-width: 1100px) {
    width: 100%;
  }
`

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.vividBlue};
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  margin-top: 2rem;
  position: relative;
  align-self: flex-start;

  &:hover {
    ::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 6px;
      background-color: ${({ theme }) => theme.colors.vividBlue};
      opacity: 0.3;
      bottom: 6px;
      left: 0;
    }
  }

  @media only screen and (max-width: 1100px) {
    align-self: center;
  }
`
