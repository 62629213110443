import React, { useState, useContext } from 'react'

import { EventsListWrapper, EventsDivider, Title } from './styles'
import Container from '../../UI/Container'
import EventItem from './ListItem'
import { LanguageContext } from '../../Layout'

import { toTimestamp } from '../../../helper'
import LoadMoreButton from '../../Category-posts-components/Category-posts-content/LoadMoreButton'

const EventsList = ({ events, title, locale }) => {
  const { i18n } = useContext(LanguageContext)
  const today = new Date().getTime()
  const [count, setCount] = useState(4)

  const showFuturePastEvents = (events, past = false) => {
    return events
      .filter((event) => {
        if (!past && toTimestamp(event.node.acf.date) > today) {
          return event
        }
        if (past && toTimestamp(event.node.acf.date) < today) {
          return event
        }
      })
      .sort((a, b) => {
        return !past
          ? toTimestamp(a.node.acf.date) - toTimestamp(b.node.acf.date)
          : toTimestamp(b.node.acf.date) - toTimestamp(a.node.acf.date)
      })
      .map((event) => <EventItem key={event.node.id} event={event.node} locale={locale} />)
  }

  return (
    <EventsListWrapper>
      <Container>
        {showFuturePastEvents(events).slice(0, 2)}
        <EventsDivider />
        <Title>{title}</Title>
        {showFuturePastEvents(events, true).slice(0, count)}
        {count < events.length && (
          <LoadMoreButton text={i18n.loadMore} callback={() => setCount((count) => count + 4)} />
        )}
      </Container>
    </EventsListWrapper>
  )
}

export default EventsList
