import React from 'react'
import Img from 'gatsby-image'

import { EventItemWrapper, ImageContainer, Description, Date, Title, Location, Details, StyledLink } from './styles.js'

import { formatDate } from '../../../../helper'

const EventItem = ({ event, locale }) => {
  const {
    acf: { date, title, location, details, link_text, url },
    featured_media: {
      localFile: {
        childImageSharp: { fixed }
      }
    }
  } = event

  return (
    <EventItemWrapper>
      <ImageContainer>
        <Img fixed={fixed} className="event-image" />
      </ImageContainer>
      <Description>
        <Date>{formatDate(date, locale)}</Date>
        <Title>{title}</Title>
        <Location>{location}</Location>
        <Details dangerouslySetInnerHTML={{ __html: details }} />
        <StyledLink href={url} target="_blank" rel="noreferrer noopener">{link_text}</StyledLink>
      </Description>
    </EventItemWrapper>
  )
}

export default EventItem
